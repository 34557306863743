import React, { useState } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
    Grid,
    Container,
    TextField,
    MenuItem,
    StyledEngineProvider,
    FormControlLabel,
    Checkbox,
    Button
} from '@mui/material';
import "./form.css";
import Pdf from "../assets/aydinlatma.pdf";
import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebase';

function Form() {
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [schoolNo, setSchoolNo] = useState("")
    const [email, setEmail] = useState("")
    const [birthday, setBirthday] = useState(null)
    const [faculty, setFaculty] = useState("")
    const [classLevel, setClassLevel] = useState("")
    const [address, setAddress] = useState("")
    const [coordi1, setCoordi1] = useState("")
    const [coordi2, setCoordi2] = useState("")
    const [whyAcm, setWhyAcm] = useState("")
    const [whereSeen, setWhereSeen] = useState("")
    const [isActive, setIsActive] = useState(false)
    const [isWhatsapp, setIsWhatsapp] = useState(false)
    const [isPromotion, setIsPromotion] = useState(false)
    const [isAccepted, setIsAccepted] = useState(false)

    const [isClicked, setIsClicked] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        //Password check
        try {
            if (!isClicked) {
                setIsClicked(true);
                console.log(coordi1)
                const docRef = await addDoc(collection(db, "users2023SYN"), {
                    name: name,
                    phone: phone,
                    schoolNo: schoolNo,
                    email: email,
                    birthday: birthday,
                    faculty: faculty,
                    classLevel: classLevel,
                    address: address,
                    coordi1: coordi1,
                    coordi2: coordi2,
                    whyAcm: whyAcm,
                    whereSeen: whereSeen,
                    isActive: isActive,
                    isWhatsapp: isWhatsapp,
                    isPromotion: isPromotion,
                    createdAt: new Date().getTime(),
                });
                if (docRef != null) {
                    console.log("Document written with ID: ", docRef.id);
                    setName("");
                    setPhone("");
                    setSchoolNo("");
                    setEmail("");
                    setFaculty("");
                    setClassLevel("");
                    setAddress("");
                    setCoordi1("");
                    setCoordi2("");
                    setWhyAcm("");
                    setWhereSeen("");
                    setIsAccepted(false);
                    setIsActive(false);
                    setIsPromotion(false);
                    setIsWhatsapp(false);
                    setBirthday(null);
                    alert("Formunuz başarıyla gönderilmiştir.")
                    setIsClicked(false);
                }
            }
        } catch (e) {
            console.log(e);
            console.error("Error adding document: ", e);
            alert("Form gönderilemedi lütfen tekrar deneyin.");
            setIsClicked(false);
        }
    };
    return (
        <StyledEngineProvider injectFirst>
            <Container maxWidth="md">
                <form onSubmit={handleSubmit}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item sm={12} md={6}>
                                <TextField
                                    color="primary"
                                    label="Ad Soyad"
                                    autoComplete="Name"
                                    variant="outlined"
                                    required
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    label="Telefon Numarası"
                                    autoComplete="phone"
                                    variant="outlined"
                                    placeholder="05551112233"
                                    InputLabelProps={{ shrink: true }}
                                    required
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    label="Okul No"
                                    variant="outlined"
                                    required
                                    value={schoolNo}
                                    onChange={e => setSchoolNo(e.target.value)}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    label="Email"
                                    autoComplete="Email"
                                    variant="outlined"
                                    type="email"
                                    required
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    label="Bölüm"
                                    variant="outlined"
                                    required
                                    value={faculty}
                                    onChange={e => setFaculty(e.target.value)}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    label="Sınıf"
                                    variant="outlined"
                                    required
                                    value={classLevel}
                                    onChange={e => setClassLevel(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="baseline"
                        >
                            <Grid item sm={12} md={6}>
                                <LocalizationProvider dateAdapter={DateFnsUtils}>
                                    <DatePicker
                                        sx={{
                                            width: 300,
                                            margin: 4,
                                        }}
                                        margin="normal"
                                        required
                                        label="Doğum Tarihi"
                                        InputLabelProps={{ shrink: true }}
                                        inputFormat="dd/MM/yyyy"
                                        value={birthday}
                                        onChange={v => setBirthday(v)}
                                        variant="outlined"
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    label="Kaldığı Yer"
                                    variant="outlined"
                                    required
                                    select
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                    helperText="Örgün Eğitim Sırasında Adres">
                                    <MenuItem value={"Belli Değil"}>Belli Değil</MenuItem>
                                    <MenuItem value={"Kampüs İçi"}>Kampüs İçi</MenuItem>
                                    <MenuItem value={"Kampüs Dışı"}>Kampüs Dışı</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                label="1. Tercih"
                                variant="outlined"
                                required
                                select
                                value={coordi1}
                                onChange={e => setCoordi1(e.target.value)}>
                                <MenuItem value={"TEK"}>Teknik Etkinlik Koordinatörlüğü</MenuItem>
                                <MenuItem value={"SEK"}>Sosyal Etkinlik Koordinatörlüğü</MenuItem>
                                <MenuItem value={"HİK"}>Halkla İlişkiler Koordinatörlüğü</MenuItem>
                                <MenuItem value={"KİK"}>Kurumsal İlişkiler Koordinatörlüğü</MenuItem>
                                <MenuItem value={"RT"}>Reklam Tanıtım Koordinatörlüğü</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                label="2. Tercih"
                                variant="outlined"
                                required
                                select
                                value={coordi2}
                                onChange={e => setCoordi2(e.target.value)}>
                                <MenuItem value={"TEK"}>Teknik Etkinlik Koordinatörlüğü</MenuItem>
                                <MenuItem value={"SEK"}>Sosyal Etkinlik Koordinatörlüğü</MenuItem>
                                <MenuItem value={"HİK"}>Halkla İlişkiler Koordinatörlüğü</MenuItem>
                                <MenuItem value={"KİK"}>Kurumsal İlişkiler Koordinatörlüğü</MenuItem>
                                <MenuItem value={"RT"}>Reklam Tanıtım Koordinatörlüğü</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                label="Bizden nasıl haberdar oldunuz?"
                                variant="outlined"
                                required
                                select
                                value={whereSeen}
                                onChange={e => setWhereSeen(e.target.value)}>
                                <MenuItem value={"Sosyal Medya"}>Sosyal Medya</MenuItem>
                                <MenuItem value={"Kampüs İçi Tanıtım"}>Kampüs İçi Tanıtım</MenuItem>
                                <MenuItem value={"Whatsapp Grupları"}>Whatsapp Grupları</MenuItem>
                                <MenuItem value={"Geçmiş Etkinlikler"}>Geçmiş Etkinlikler</MenuItem>
                                <MenuItem value={"Arkadaş"}>Arkadaş</MenuItem>
                                <MenuItem value={"Diğer"}>Diğer</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TextField
                                className="text-field"
                                helperText="Neden bu koordinatörlükleri tercih ettiniz"
                                label={"Neden " + coordi1 + " ve " + coordi2 + " ?"}
                                variant="outlined"
                                multiline
                                rows={4}
                                value={whyAcm}
                                onChange={e => setWhyAcm(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Container maxWidth="sm">
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                        // alignItems="center"
                        >

                            <Grid item >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isActive}
                                            onChange={e => setIsActive(!isActive)}
                                            color="primary"
                                        />
                                    }
                                    label="Aktif üye olmak istiyorum."
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isWhatsapp}
                                            onChange={e => setIsWhatsapp(!isWhatsapp)}
                                            color="primary"
                                        />
                                    }
                                    label="Whatsapp ACM Hacettepe gruplarına eklenmek istiyorum."
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isPromotion}
                                            onChange={e => setIsPromotion(!isPromotion)}
                                            color="primary"
                                        />
                                    }
                                    label="Duyuru veya yenilikleri SMS ve E-posta yoluyla almak istiyorum."
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAccepted}
                                            required
                                            onChange={e => setIsAccepted(!isAccepted)}
                                            color="primary"
                                        />
                                    }
                                    label={<><a href={Pdf} target="_blank" rel="noopener noreferrer">Aydınlatma metnini</a> okudum ve onaylıyorum.</>}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <div>
                        <Button type="submit" variant="contained" color="primary">
                            Kayıt Ol
                        </Button>
                    </div>
                </form>
            </Container>
        </StyledEngineProvider>
    );

}

export default Form;
