import './App.css';
import Navbar from "./Navbar"
import Form from "./pages/Form"
import Syn from "./pages/Syn"
import BizKimiz from './pages/BizKimiz';
import Footer from './components/footer';
import { Route, Routes } from "react-router-dom"
import { ThemeProvider, createTheme } from '@mui/material/styles';




const theme = createTheme({
  palette: {
    primary: {
      main: "#7712b6"
    },
    secondary: {
      main: "#000000"
    }
  },
});


function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Navbar />
        <div className="container">
          <Routes>
            <Route path="/" element={<Form />} />
            <Route path="/form" element={<Form />} />
            <Route path="/syn" element={<Syn />} />
            {/* <Route path="/bizkimiz" element={<BizKimiz />} /> */}
          </Routes>
        </div>
        <Footer />
      </ThemeProvider>
    </>
  )
}


export default App;
