import { Grid, Typography, Divider } from "@mui/material";
import { Box } from "@mui/system";
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import Link from '@mui/material/Link';



export default function Footer() {
    return (
        <Box sx={{
            //background:"linear-gradient(90deg, rgba(0,17,112,1) 0%, rgba(96,47,200,1) 50%, rgba(205,22,167,1) 100%)",
            background: "rgba(119,19,183,255)",

            color: "white",
            pt: 8,
            pb: 8,
            alignItems: "center",
            fontSize: { xs: '12px', md: "14px" }
        }}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={6}
            >
                <Grid item xs={8} md={4}>
                    <a href="https://www.acmhacettepe.com/">
                        <img alt='' className="acm-logo" src={"https://www.acmhacettepe.com/static/media/acm-white-wide.39d97f08beb6d5bcd831694256f1e5e5.svg"} style={{ width: 240, height: 80 }} />
                    </a>
                </Grid>

                <Grid item xs={8} md={4} >
                    <Typography sx={{ fontSize: { xs: '16px', md: '18px' } }}>İLETİŞİM</Typography>
                    <Typography>Bize sosyal medya hesaplarımız ya da iletisim@acmhacettepe.com mail adresimiz üzerinden ulaşabilirsiniz.</Typography>

                    <box>
                        <Link href="https://www.instagram.com/acmhacettepe/" target="_blank" >
                            <InstagramIcon sx={{ color: "white", mr: "15px", "&:hover": { color: 'gray' }, mt: "15px" }} />
                        </Link>
                        <Link href="https://www.linkedin.com/company/acm-hacettepe" target="_blank" >
                            <LinkedInIcon sx={{ color: "white", mr: "15px", "&:hover": { color: 'gray' }, mt: "15px" }} />
                        </Link>
                        <Link href="https://twitter.com/acmhacettepe" target="_blank" >
                            <TwitterIcon sx={{ color: "white", mr: "15px", "&:hover": { color: 'gray' }, mt: "15px" }} />
                        </Link>
                    </box>
                </Grid>

            </Grid>

            <Divider variant="middle" sx={{ borderColor: 'white', opacity: '60%', borderBottomWidth: 0.5, marginLeft: '12%', marginRight: '12%', marginTop: '50px' }} />
            <Typography textAlign={'center'} sx={{ fontSize: { xs: '14px', md: '16px' }, marginTop: '10px' }}>ACM Hacettepe Ar-Ge ©</Typography>

        </Box>
    )

}