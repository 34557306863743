import { Link, useMatch, useResolvedPath } from "react-router-dom"

export default function Navbar() {
  return (
    <nav className="nav">
      <a href="https://www.acmhacettepe.com/">
        <img alt='' className="acm-logo" src={"https://www.acmhacettepe.com/static/media/acm-white-wide.39d97f08beb6d5bcd831694256f1e5e5.svg"} style={{ width: 150, height: 50 }} />
      </a>
      <ul className="ul">
        <CustomLink to="/Form">Kayıt Formu</CustomLink>
        <CustomLink to="/Syn">SYN</CustomLink>
        {/* <CustomLink to="/BizKimiz">Biz Kimiz</CustomLink> */}
      </ul>
    </nav>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}